import axios from "axios";

window.addEventListener('load', () => {

    function showDisclaimer(){
        let disclaimerParagraph = document.createElement("div");

        disclaimerParagraph.innerHTML = "Na Vašu otázku odpovie online chatovací robot – chatbot, ktorý je komunikačný nástroj pracujúci s využitím umelej inteligencie. Odpovede a údaje poskytnuté prostredníctvom chatbota majú informatívny charakter a nie sú právne záväzné.";
        disclaimerParagraph.classList.add("govuk-body-s", "message-bot");
        dataContainer.appendChild(disclaimerParagraph);

        dataContainer.scrollTo(0, dataContainer.scrollHeight);
    }

    function parseLink(inputString) {
        const linkRegex = /https:\/\/\S+\.sk\/\S+\.php(?:\?\S+=\S+)?/g;
        return inputString.replace(linkRegex, (match) => {
          return `<a target="_blank" class="govuk-link" href="${match}">${match}</a>`;
        });
      }

    let dataContainer = document.getElementById('chat-window');

    document.querySelector("#chat-disclaimer-button").addEventListener("click", showDisclaimer, { once: true });

    document.querySelector('#chat-input-wrapper').addEventListener('submit', (e) => {
        e.preventDefault();

        let input = document.querySelector('#chat-input');
        let questionParagraph = document.createElement("div");

        if(input.value) {
            questionParagraph.textContent = input.value;
            questionParagraph.classList.add("govuk-body-s", "message-user");
            dataContainer.appendChild(questionParagraph);

            dataContainer.scrollTo(0, dataContainer.scrollHeight);

            const message = input.value;
            input.value = null;

            let answerParagraph = document.createElement("div");
            answerParagraph.classList.add("govuk-body-s", "message-bot");

            answerParagraph.innerHTML = '<div class="typing-dot"></div><div class="typing-dot"></div><div class="typing-dot"></div>'
            dataContainer.appendChild(answerParagraph);
            dataContainer.scrollTo(0, dataContainer.scrollHeight);

            axios.post('./api/chat/send-message.php', {message: message})
                .then(function(response) {
                    answerParagraph.innerHTML = `<p>${parseLink(response.data.response).replaceAll(')', '').replaceAll('(', ' ')}</p>`;
                    dataContainer.scrollTo(0, dataContainer.scrollHeight);
                })
                .catch(function(error) {
                    console.error(error);
                    if (error && error.response && error.response.data) {
                        alert(error.response.data);
                    }
                });
        }


    })

    document.querySelector("#button-chat").addEventListener("click", () => {
        document.querySelector("#chat-overlay").classList.remove("hidden");
        
        if(window.innerWidth <= 768) {
            document.documentElement.style.maxHeight = '100dvh';
            document.documentElement.style.overflowY = 'hidden';
        }
    })

    document.querySelector("#chat-header #chat-close").addEventListener("click", () => {
        document.querySelector("#chat-overlay").classList.add("hidden");

        if(window.innerWidth <= 768) {
            document.documentElement.style.maxHeight = 'none';
            document.documentElement.style.overflowY = 'inherit';
        }
    })
})
